/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
html,
body,
#root {
  height: 100%;
}

body {
  font-family: $font-default;
  background: darken($secondary, 5);
  color: $white;
}

a,
.a {
  color: $primary;
}

a:hover,
.a:hover {
  color: lighten($primary, 10);
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h3, {
  font-family: $font-primary;
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  display: none;
  right: 15px;
  bottom: 15px;
  z-index: 995;

  i {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    width: 44px;
    height: 44px;
    border-radius: 50px;
    color: $primary;
    transition: all 0.4s;
    border: 2px solid $primary;
    &:hover {
      background: $primary;
      color: $secondary;
    }
  }
}

.cool-outline-button:hover {
  color: $white;
  background: $primary;
}

.cool-outline-button {
  color: $white;
  border: 2px solid $primary;
  background-color: $secondary;
  border-radius: 50px;
  margin: 20px 0 0 0;
  padding: 10px 25px;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1px;
  transition: 0.3s;
  display: inline-block;
}

.card {
  background-color: darken($secondary, 5);
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  border: 1px solid;
  border-color: lighten($secondary, 25);
}

del {
  color: $gray;
}


/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}
