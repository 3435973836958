.fab-button {
    position:fixed;
    right:1rem;
    bottom:1rem;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    opacity: .75;
    z-index: 10;

    i {
        font-size: 36px;
        
    }

    span {
        position: fixed;
        bottom: 3.25rem;
        right: 2.4rem;
        font-weight: 600;
    }
}