.modal {
    background-color: rgba(0, 0, 0, .5);
    font-family: "Poppins", sans-serif;

    .modal-content {
        background-color: $black;
    }

    button.close {
        color: $primary;
    }
}