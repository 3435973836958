/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  background: darken($secondary, 10);
  padding: 0;
  color: $white;
  font-size: 14px;


  .footer-top {
    background: darken($secondary, 5);
    border-top: 1px solid lighten($secondary, 10);
    border-bottom: 1px solid lighten($secondary, 5);
    padding: 60px 0 30px 0;

    .footer-info {
      margin-bottom: 30px;
      h3, .h3 {
        font-size: 24px;
        margin: 0 0 20px 0;
        padding: 2px 0 2px 0;
        line-height: 1;
        font-weight: 300;
        text-transform: uppercase;
        font-family: $font-secondary;
      }

      p {
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 0;
        font-family: $font-primary;
        color: $white;
      }
    }

    .social-links {
      a {
        font-size: 18px;
        display: inline-block;
        background: lighten($secondary, 5);
        color: $white;
        line-height: 1;
        padding: 8px 0;
        margin-right: 4px;
        border-radius: 50%;
        text-align: center;
        width: 36px;
        height: 36px;
        transition: 0.3s;
        &:hover {
          background: $primary;
          color: #fff;
          text-decoration: none;
        }
      }
    }

    h4 {
      font-size: 16px;
      font-weight: 600;
      color: #fff;
      position: relative;
      padding-bottom: 12px;
    }

    .footer-links {
      display: flex;;
      ul {
        list-style: none;
        padding: 0;
        margin: auto;

        i {
          padding-right: 2px;
          color: $primary;
          font-size: 18px;
          line-height: 1;
        }

        li {
          padding: 10px 0;
          &:first-child {
            padding-top: 0;
          }
          display: flex;
          align-items: center;
          justify-content: center;
        }

        a {
          color: $white;
          transition: 0.3s;
          display: inline-block;
          line-height: 1;
          &:hover {
            color: $primary;
          }
        }
      }
    }

    .footer-newsletter {
  
      form {
        margin-top: 30px;
        background: lighten($secondary, 5);
        padding: 6px 10px;
        position: relative;
        border-radius: 50px;
        border: 1px solid lighten($secondary, 15);
  
        input[type="email"] {
          border: 0;
          padding: 4px;
          width: calc(100% - 110px);
          background: lighten($secondary, 5);
          color: white;
        }
        input[type="submit"] {
          position: absolute;
          top: -1px;
          right: -1px;
          bottom: -1px;
          border: 0;
          background: none;
          font-size: 16px;
          padding: 0 20px 2px 20px;
          background: $primary;
          color: $white;
          transition: 0.3s;
          border-radius: 50px;
          &:hover {
            background: lighten($primary, 5);
          }
        }
      }
    }

  }

  .copyright {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: $secondary;
  }

  .credits {
    text-align: center;
    color: $white;
    height: 100px;
    display: flex;

    * {
      margin: auto;
    }
  }
}
