/*--------------------------------------------------------------
# Top Bar
--------------------------------------------------------------*/
#topbar {
  height: 40px;
  font-size: 14px;
  transition: all 0.5s;

  &.topbar-scrolled {
    top: -40px;
  }

  .contact-info {
    color: white;
    i {
      color: lighten($primary, 10);
      padding-right: 4px;
    }

    i + i {
      margin-left: 15px;
    }
  }

  .languages{
    ul {
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      padding: 0;
      margin: 0;
      color: $primary;

      a {
        color: white;
      }
  
      li + li {
        padding-left: 10px;
      }
  
      li + li::before {
        display: inline-block;
        padding-right: 10px;
        color: rgba(white, .5);
        content: "/";
      }
    }
  }
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  background: rgba(darken($secondary, 5), .6);
  border-bottom: 1px solid rgba(darken($secondary, 5), .6);
  transition: all 0.5s;
  z-index: 997;
  padding: 15px 0;
  top: 40px;
  display: flex;

  &.header-scrolled {
    top: 0;
    background: rgba(darken($secondary, 15), 1);
    border-bottom: 1px solid lighten($secondary, 10);
    max-height: 60px;
  }

  .logo {
    font-size: 28px;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-weight: 300;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-family: $font-secondary;
    white-space: nowrap;

    a {
      color: #fff;
    }

    img {
      max-height: 40px;
    }
  }
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/

/* Desktop Navigation */
.nav-menu {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  > ul {
    display: flex;
    align-items: center;
  }

  > ul > li {
    position: relative;
    white-space: nowrap;
    padding-left: 28px;
  }
  
  a {
    display: block;
    position: relative;
    color: white;
    transition: 0.3s;
    font-size: 14px; 
    font-family: $font-default;
  }

  a:hover, .active > a, li:hover > a {
    color: lighten($primary, 10);
  }

  .book-a-table a {
    border: 2px solid $primary;
    color: $white;
    border-radius: 50px;
    padding: 8px 25px;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 1px;
    transition: 0.3s;
    &:hover {
      background: $primary;
      color: $white;
    }
  }

  .drop-down ul {
    display: block;
    position: absolute;
    left: 15px;
    top: calc(100% + 30px);
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    padding: 10px 0;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: 0.3s;
  }
  
  .drop-down:hover > ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
  }

  .drop-down li {
    min-width: 180px;
    position: relative;
  }

  .drop-down ul a {
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 500;
    text-transform: none;
    color: $secondary;
  }

  .drop-down ul a:hover, .drop-down ul .active > a, .drop-down ul li:hover > a {
    color: $primary;
  }
  
  .drop-down > a:after {
    content: "\ea99"; 
    font-family: IcoFont;
    padding-left: 5px;
  }

  .drop-down {

    .drop-down ul {
      top: 0;
      left: calc(100% - 30px);
    }

    .drop-down:hover > ul {
      opacity: 1;
      top: 0;
      left: 100%;
    }

    .drop-down > a {
      padding-right: 35px;
    }

    .drop-down > a:after {
      content: "\eaa0"; 
      font-family: IcoFont;
      position: absolute;
      right: 15px;
    }

  }
  
}

@media (max-width: 1366px) {
  .nav-menu .drop-down {

    .drop-down ul {
      left: -90%;
    }

    .drop-down:hover > ul {
      left: -100%;
    }

    .drop-down > a:after {
      content: "\ea9d"; 
    }
  }
}

/* Mobile Navigation */
.mobile-nav-toggle {
  z-index: 9998;
  border: 0;
  background: none;
  font-size: 24px;
  transition: all 0.4s;
  outline: none !important;
  line-height: 1;
  cursor: pointer;
  text-align: right;

  div {
    width: 35px;
    height: 4px;
    background-color: $white;
    border-radius: 10px;
  }

  div:nth-child(2) {
    margin-top: 7px;
    margin-bottom: 7px;
  }
  

}

$mobile_nav_spacing: 15px;
.mobile-nav {
  position: fixed;
  top: $mobile_nav_spacing;
  right: $mobile_nav_spacing;
  bottom: $mobile_nav_spacing;
  left: $mobile_nav_spacing;
  z-index: 9999;
  overflow-y: auto;
  background: $secondary;
  border: 2px solid rgba(white, .1);
  transition: ease-in-out 0.2s;
  opacity: 0;
  visibility: hidden;
  border-radius: 10px;
  padding: 10px 0;

  * {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .mobile-nav-close {
    margin: 0 15px 0 auto;
    display: block;
    border: 0;
    background: none;
    font-size: 36px;
    transition: all 0.4s;
    outline: none !important;
    line-height: 1;
    cursor: pointer;
    text-align: right;
    color: rgba(white, .5);

    &:hover {
      color: $white;
    }
  
  }

  a {
    display: block;
    position: relative;
    color: $white;
    padding: 10px 20px;
    font-weight: 500;
    outline: none;
    transition: 0.3s;
  }

  a:hover, .active > a, li:hover > a {
    color: $primary;
    text-decoration: none;
  }

  .drop-down > a:after {
    content: "\ea99"; 
    font-family: IcoFont;
    padding-left: 10px; 
    position: absolute;
    right: 15px;
  }

  .active.drop-down > a:after {
    content: "\eaa1";
  }

  .book-a-table a {
    color: $white;
    border: 2px solid $primary;
    border-radius: 50px;
    margin: 20px 0 0 0;
    padding: 10px 25px;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 1px;
    transition: 0.3s;
    display: inline-block;
    &:hover {
      background: $primary;
      color: $white;
    }
  }
  
  .drop-down > a {
    padding-right: 35px;
  }
  
  .drop-down ul {
    display: none;
    overflow: hidden;
  }

  .drop-down li {
    padding-left: 20px;
  }
}

.mobile-nav-overly {
  width:100%;
  height:100%;
  z-index:9997;
  top:0;
  left:0;
  position:fixed;
  background: rgba(darken($secondary, 10), 0.6);
  overflow: hidden;
  display: none;
  transition: ease-in-out 0.2s;
}

.mobile-nav-active {
  overflow: hidden;

  .mobile-nav {
    opacity: 1;
    visibility: visible;
  }

  .mobile-nav-toggle i {
    color: #fff;
  }
}
