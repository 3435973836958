/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  height: 100vh;
  background: url("../images/b8c6b9e0-83d1-4e1e-9a8a-97f51757067f.jpeg") center center;
  background-size: cover;
  position: relative;
  padding: 0;
  margin-top: 0px;

  &:before {
    content: "";
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }

  .container {
    padding-top: 110px;
    @media (max-width: 992px) {
      padding-top: 98px;
    }
  }

  h1 {
    margin: 0;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    color: #fff;
    font-family: $font-secondary;
    span {
      color: $primary;
    }
  }

  h2 {
    color: #eee;
    margin-bottom: 10px 0 0 0;
    font-size: 22px;
  }

  .btns {
    margin-top: 30px;
  }

  .btn-menu, .btn-book {
    font-weight: 600;
    font-size: 13px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    display: inline-block;
    padding: 12px 30px;
    border-radius: 50px;
    transition: 0.3s;
    line-height: 1;
    color: white;
    border: 2px solid $primary;
    &:hover {
      background: $primary;
      color: #fff;
    }
  }

  .btn-book {
    margin-left: 15px;
  }

  .play-btn {
    width: 94px;
    height: 94px;
    background: radial-gradient( $primary 50%, rgba($primary, 0.4) 52%);
    border-radius: 50%;
    display: block;
    position: relative;
    overflow: hidden;
  }

  .play-btn::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-40%) translateY(-50%);
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #fff;
    z-index: 100;
    transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  .play-btn::before {
    content: '';
    position: absolute;
    width: 120px;
    height: 120px;
    animation-delay: 0s;
    animation: pulsate-btn 2s;
    animation-direction: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: steps;
    opacity: 1;
    border-radius: 50%;
    border: 5px solid rgba($primary, 0.7);
    top: -15%;
    left: -15%;
    background: rgba(198, 16, 0, 0);
  }

  .play-btn:hover::after {
    border-left: 15px solid $primary;
    transform: scale(20);
  }

  .play-btn:hover::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-40%) translateY(-50%);
    width: 0;
    height: 0;
    border: none;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #fff;
    z-index: 200;
    animation: none;
    border-radius: 0;
  }

  @media (min-width: 1024px) {
    background-attachment: fixed;
  }

  @media (max-width: 992px) {
    .play-btn {
      margin-top: 30px;
    }
  }

  @media (max-height: 500px) {
    height: auto;
    .container {
      padding-top: 130px;
      padding-bottom: 60px;
    }
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 28px;
      line-height: 36px;
    }
    h2 {
      font-size: 18px;
      line-height: 24px;
    }
  }

}

@keyframes pulsate-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1
  }
  100% {
    transform: scale(1, 1);
    opacity: 0
  }
}
