/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
.loading-spinner {
  position: relative;
  display: flex;
  justify-content: center;
  margin: auto;
  padding-bottom: 60px;
}

.loading-spinner:before {
  content: "";
  position: absolute;
  border: 6px solid $secondary;
  border-top-color: $primary;
  border-bottom-color: $primary;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: animate-preloader 1s linear infinite;
  animation: animate-preloader 1s linear infinite;
}

@-webkit-keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
