@import 'variables';

@import "~bootstrap/scss/bootstrap";
@import "./vendor/icofont/icofont.min.css";
@import "~react-image-lightbox/style.css";

@import 'general';
@import 'header';
@import 'hero';
@import 'sections';
@import 'footer';
@import 'fab_button';

@import 'loading_page';
@import 'modal';