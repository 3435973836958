.jobs {
  @import "../../../scss//variables";

  p {
    line-height: 2rem;
  }

  .jobs-img {
    position: relative;
    transition: 0.5s;

    img {
      max-width: 75%;
      border: 4px solid rgba(white, 0.2);
      position: relative;
    }
    $outer_border: 5px solid $primary;
    $outer_border_pos: 20px;
    $outer_border_pos_hover: 10px;

    &::before {
      position: absolute;
      left: $outer_border_pos;
      top: $outer_border_pos;
      width: 60px;
      height: 60px;
      z-index: 1;
      //   content: "";
      border-left: $outer_border;
      border-top: $outer_border;
      transition: 0.5s;
    }

    &::after {
      position: absolute;
      right: $outer_border_pos;
      bottom: $outer_border_pos;
      width: 60px;
      height: 60px;
      z-index: 2;
      //   content: "";
      border-right: $outer_border;
      border-bottom: $outer_border;
      transition: 0.5s;
    }

    &:hover {
      transform: scale(1.03);

      &::before {
        left: $outer_border_pos_hover;
        top: $outer_border_pos_hover;
      }

      &::after {
        right: $outer_border_pos_hover;
        bottom: $outer_border_pos_hover;
      }
    }
  }

  button[type="submit"] {
    background: $primary;
    border: 0;
    padding: 10px 35px;
    color: #fff;
    transition: 0.4s;
    border-radius: 50px;
    &:hover {
      background: lighten($primary, 5);
    }
  }
}
